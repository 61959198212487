import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import Configs from '@shared/constants/configs';
import { Observable } from 'rxjs';

@Injectable()
export class CallApiService extends BaseApiService {
  getToken(companyId) {
    const query = {
      companyId: companyId,
    };

    return this.http.get(this.baseUrl + '/call/token', {params: query});
  }

  makeCall(phoneNumber, callerId) {
    const body = {
      phoneNumber,
      callerId,
    };

    return this.http.post(this.baseUrl + '/call/make', body);
  }
}
