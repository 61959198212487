import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from '@auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: fuseAnimations
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  forgotPasswordFormErrors: any;
  spinner = false;
  error: null;

  pageTitle = 'Recover your password';
  pageGuide = 'To recover your password, enter the email address.';

  constructor(private fuseConfig: FuseConfigService,
              private formBuilder: FormBuilder,
              private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router) {

    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.forgotPasswordFormErrors = {
      email: {}
    };

    console.log(this.route.snapshot.queryParams)
    switch (this.route.snapshot.queryParams.code) {
      case 'empty_password':
        this.pageTitle = 'Set your password';
        this.pageGuide = 'To set your password, enter the email address.';
        break;
      case 'password_expired':
        this.pageTitle = 'Change your password';
        this.pageGuide = 'To change your password, enter the email address.';
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: [this.route.snapshot.queryParams.email || '', [Validators.required, Validators.email]]
    });

    this.forgotPasswordForm.valueChanges.subscribe(() => {
      this.onForgotPasswordFormValuesChanged();
    });

    const map = this.route.snapshot.paramMap;
  }

  onForgotPasswordFormValuesChanged() {
    for (const field in this.forgotPasswordFormErrors) {
      if (!this.forgotPasswordFormErrors.hasOwnProperty(field)) {
        continue;
      }

      this.forgotPasswordFormErrors[field] = {};

      const control = this.forgotPasswordForm.get(field);

      if (control && control.dirty && !control.valid) {
        this.forgotPasswordFormErrors[field] = control.errors;
      }
    }
  }

  forgotPassword() {
    const value = this.forgotPasswordForm.value;

    this.spinner = true;

    const email = value.email;
    this.authService.requestResetCode(email)
      .subscribe(() => {
        this.router.navigate(['/auth/security-code'], {queryParams: {email: email}});
        this.spinner = false;
      }, (response: any) => {
        this.error = response.error.message;
        this.spinner = false;
      });

    return false;
  }

}
