import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from '@auth/auth.service';
import { finalize } from 'rxjs/operators';
import { StrongPasswordValidator } from '@shared/dk-components/dk-strong-password/dk-strong-password.directive';

@Component({
  selector: 'dk-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  animations: fuseAnimations
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  valFn: ValidatorFn;     // the validation function

  changePasswordFormErrors: any;
  spinner = false;
  email: string = null;
  code: string = null;
  error: null;

  user_inputs: string[] = ['foobar', 'barfoo'];
  level = '2';

  user = {
    password: '',
    strength: 0
  };

  constructor(private fuseConfig: FuseConfigService,
              private formBuilder: FormBuilder,
              private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router) {

    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: true
        },
        toolbar  : {
          hidden: true
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.route.queryParams.subscribe(params => {
      this.email = params.email;
      this.code = params.code;
    });

    if (this.email === undefined) {
      this.router.navigate(['/auth/forgot-password']);
    }

    if (this.code === undefined) {
      this.router.navigate(['/auth/security-code'], {
        queryParams : {
          email: this.email
        }
      });
    }

    this.changePasswordFormErrors = {
      password: {}
    };

    this.valFn = StrongPasswordValidator(this.level, this.user_inputs);
  }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, this.valFn]]
    });

    this.changePasswordForm.patchValue({ password: this.user.password });

  }

  changePassword() {
    const value = this.changePasswordForm.value;

    this.spinner = true;

    const password = value.password;
    this.authService.resetPassword(this.email, this.code, password)
      .subscribe(() => {

        this.authService.login(this.email, password)
          .pipe(finalize(() => this.spinner = false))
          .subscribe(() => {
            // Get the redirect URL from our auth service
            // If no redirect has been set, use the default
            const redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '';
            // Redirect the user
            this.router.navigate([redirect]);
          });
      }, (response: any) => {
        this.error = response.error.message;
        this.spinner = false;
      });

    return false;
  }

  // Current password strength emitted from the strength-meter: Can't think of a practical use!
  onStrength({strength}) {
    this.user.strength = strength;
  }

}
