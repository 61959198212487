import {Injectable} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';

@Injectable()
export class SpinnerService {

  visible: boolean;

  constructor(router: Router) {
    this.visible = false;

    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.visible = true;
      } else if (event instanceof NavigationEnd) {
        this.visible = false;
      }
    });
  }

  start() {
    this.visible = true;
  }

  stop() {
    this.visible = false;
  }

}
