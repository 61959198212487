import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FullNamePipe} from './pipes/fullName.pipe';
import {TelPipe} from './pipes/tel.pipe';
import {DisplayNamePipe} from './pipes/display-name.pipe';
import {ProfileApiService} from './apis/profile-api.service';
import {AuthApiService} from './apis/auth-api.service';
import {CompanyApiService} from './apis/company-api.service';
import {ReportApiService} from './apis/report-api.service';
import {DashboardApiService} from './apis/dashboard-api.service';
import {ChatApiService} from './apis/chat-api.service';
import {PusherService} from './services/pusher.service';
import {ColorPipe} from '@shared/pipes/color.pipe';
import {MomentModule} from 'ngx-moment';
import {DkComponentModule} from '@shared/dk-components/dk-component.module';
import {CookieService} from 'ngx-cookie-service';
import {DkTimeAgoPipe} from '@shared/pipes/dk-time-ago';
import {DkAvatarComponent} from '@shared/dk-components/dk-avatar/dk-avatar.component';
import {ChartSettingService} from '@shared/services/chart-setting.service';
import {UserApiService} from '@shared/apis/user-api.service';
import {SpinnerService} from '@shared/services/spinner.service';
import { MaintenanceApiService } from '@shared/apis/maintenance-api.service';
import { BroadcastApiService } from '@shared/apis/broadcast-api.service';
import { SurveyApiService } from '@shared/apis/survey-api.service';
import { CallApiService } from '@shared/apis/call-api.service';
import Configs from '@shared/constants/configs';

@NgModule({
  imports: [
    DkComponentModule,
    CommonModule,
    MomentModule,
  ],
  providers: [
    CookieService,
    AuthApiService,
    DashboardApiService,
    ChatApiService,
    SurveyApiService,
    BroadcastApiService,
    ReportApiService,
    PusherService,
    CompanyApiService,
    ProfileApiService,
    ChartSettingService,
    UserApiService,
    MaintenanceApiService,
    SpinnerService,
    CallApiService,
  ],
  declarations: [
    FullNamePipe,
    DisplayNamePipe,
    TelPipe,
    ColorPipe,
    DkTimeAgoPipe,
    DkAvatarComponent,
  ],
  exports: [
    FullNamePipe,
    DisplayNamePipe,
    TelPipe,
    ColorPipe,
    MomentModule,
    DkTimeAgoPipe,
    DkAvatarComponent
  ]
})

export class DkSharedModule {
}


// print out version
console.log(`
[APP] name: ${Configs.package.name}
[APP] ver: ${Configs.package.version}
`);
