import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from '@auth/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Profile } from '@shared/enum/profile';
import { environment } from '@env/environment';
import Configs from '@shared/constants/configs';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: fuseAnimations
})
export class LoginComponent implements OnInit {

  readonly configs = Configs;
  loginForm: FormGroup;
  loginFormErrors: any;

  message: string;
  defaultErrorMessage = 'Server has encountered a problem. Please contact administrator.';

  profileName: string;

  logging = false;

  click = false;

  constructor(private fuseConfig: FuseConfigService,
              private formBuilder: FormBuilder,
              private authService: AuthService,
              private dialog: MatDialog,
              private router: Router) {

    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.loginFormErrors = {
      email: {},
      password: {}
    };

    this.profileName = Profile[environment.profile].toLowerCase();
  }

  ngOnInit() {
    const lastUser = localStorage.getItem('lastUser') || '';
    this.loginForm = this.formBuilder.group({
      email: [lastUser, [Validators.required, Validators.email]],
      password: ['', Validators.required],
      rememberMe: lastUser !== '',
    });

    this.loginForm.valueChanges.pipe(
      // takeUntil(this._unsubscribeAll),
      // debounceTime(300),
      // distinctUntilChanged()
    ).subscribe(() => this.onLoginFormValuesChanged());
  }

  onLoginFormValuesChanged() {
    for (const field in this.loginFormErrors) {
      if (!this.loginFormErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.loginFormErrors[field] = {};

      // Get the control
      const control = this.loginForm.get(field);

      if (control && control.dirty && !control.valid) {
        this.loginFormErrors[field] = control.errors;
      }
    }
  }

  login() {
    this.click = true;

    const value = this.loginForm.value;

    this.logging = true;
    this.authService.login(value.email, value.password)
      .subscribe(() => {
        this.logging = false;
        if (value.rememberMe) {
          localStorage.setItem('lastUser', value.email);
        } else {
          localStorage.removeItem('lastUser');
        }
        // Get the redirect URL from our auth service
        // If no redirect has been set, use the default
        if (this.authService.redirectUrl && this.authService.redirectUrl === '/auth/login') {
          this.authService.redirectUrl = null;
        }
        const redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '';
        // Redirect the user
        this.router.navigate([redirect]);
      }, err => {
        if (err.error?.resetPassword) {
          const redirect = '/auth/forgot-password';
          this.router.navigate([redirect],
            {
              queryParams: {
                code: err.error?.code,
                email: value.email,
              }
            });
          return;
        }
        this.logging = false;
        this.click = false;
        const error = err.error;
        const status = err.status;
        const statusCode = error.statusCode;

        let message = this.defaultErrorMessage;

        if (status === 400 || statusCode === 400) {
          message = error.message;
        }

        this.message = message;
      });
  }
}
