import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { BaseApiService } from './base-api.service';

@Injectable()
export class ProfileApiService extends BaseApiService implements Resolve<any> {

  aboutOnChanged: BehaviorSubject<any> = new BehaviorSubject({});

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getProfile(),
      ]).then(
        () => {
          resolve(null);
        },
        reject
      );
    });
  }

  getProfile(): Promise<any[]> {
    return new Promise((resolve, reject) => {

      this.http.get(this.baseUrl + '/profile')
        .subscribe((about: any) => {
          this.aboutOnChanged.next(about);
          resolve(about);
        }, reject);
    });
  }

  getProfileInfo() {
    return this.http.get(this.baseUrl + '/profile');
  }

  updateProfile(profile) {
    return this.http.patch(this.baseUrl + '/profile', {...profile})
      .subscribe(() => this.getProfile());
  }

  subscribeWebPush(token): Observable<any> {
    const data = {
      token: token
    };

    return this.http.post(`${this.baseUrl}/profile/push-tokens`, data);
  }

  unsubscribeWebPush(token): Observable<any> {
    return this.http.delete(`${this.baseUrl}/profile/push-tokens/` + encodeURIComponent(token));
  }

}
