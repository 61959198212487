import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'dk-avatar',
  templateUrl: './dk-avatar.component.html',
  styleUrls: ['./dk-avatar.component.scss']
})
export class DkAvatarComponent implements OnInit, OnChanges {
  name: string;
  @Input()
  set naming(value) {
    if (value) {
      this.name = value;
    } else {
      this.name = '?';
    }
  }


  background = this.getRandomColor();
  @Input()
  set backgroundColor(value) {
    this.background = value;
  }

  @Input('size') size = 100;

  @Input('displayType') displayType = 'none';

  @Input('letter') letter = '?';

  fontSize = 49;
  fontColor = '#FFFFFF';
  props: any = null;

  constructor() {
  }

  ngOnInit() {
    this.setCssProps();
    this.getLetter();
  }


  ngOnChanges() {
    this.setCssProps();
    this.getLetter();
  }

  getRandomColor(): string {
    const letters = '0123456789ABCDEF'.split('');
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getLetter(): void {
    if (this.name && this.name.length) {
      const nameInitials = this.name.match(/\b(\w)/g);
      if (nameInitials) {
        const nameLetters = nameInitials.slice(0, 3).join('');
        this.letter = nameLetters.toUpperCase();
      } else {
        this.letter = this.name[0];
      }
    }
  }

  setCssProps() {
    this.fontSize = (39 * this.size) / 100;
    this.props = {
      size: `${this.size}px`,
      lineheight: `${this.size}px`,
      background: this.background,
      fontSize: `${this.fontSize}px`
    };

    switch (this.displayType) {
      case 'rounded':
        this.props['borderradius'] = '5%';
        break;
      case 'circle':
        this.props['borderradius'] = '50%';
        break;
      default:
        this.props['borderradius'] = '0';
    }
  }

}
