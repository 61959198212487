import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'fuse-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class FuseConfirmDialogComponent {
  public confirmMessage: string;

  constructor(public dialogRef: MatDialogRef<FuseConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}
