import { Profile } from '@shared/enum/profile';

export const environment = {
  site: {
    name: 'MessageCamp',
  },

  profile: Profile.Dev,

  api: {
    baseUrl: '/api'
  },

  pusher: {
    key: 'aa40fba6ac8ce5e64cb7',
    cluster: 'us2',
    encrypted: true,
    debug: 'debug',
  },

  webPush: {
    publicKey: 'BIx_T-GvqFTSo6qwmHju7_fpbQ2cx3XZmIresU4OqJqO-oiwjo1o5SuFK5wXsr-wZ4KjuvT98BT7-Iij5EJkDcc'
  }
};
