export class PusherChannel {
  private channel;
  private events = {};

  constructor(channel) {
    this.channel = channel;
  }

  bind(event, callback) {
    this.events[event] = callback;
  }

  unbind(event = null) {
    if (event) {
      delete this.events[event];
    } else {
      this.events = {};
    }
  }

  binds() {
    return Object.keys(this.events).map(x => this.events[x]);
  }

  handleEvent(event, message) {
    const handler = this.events[event];
    if (!handler) {
      return;
    }
    handler(message);
  }
}
