import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';

@Injectable()
export class SurveyApiService extends BaseApiService {
  getCompanySurveys(companyId?) {
    const query = {companyId};
    return this.http.get(`${this.baseUrl}/reports/survey/list`, {params: query});
  }

  getSurveys() {
    return this.http.get(`${this.baseUrl}/survey`);
  }

  getSurveyResult(from, to, surveyId, rangeType, options) {
    const query = {
      from: from ? from.toISOString() : null,
      to: to ? to.toISOString() : null,
      surveyId,
      rangeType,
      ...options,
    };

    return this.http.get(`${this.baseUrl}/reports/survey`, {params: query});
  }
}
