import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from './shared/notification.service';
import { Profile } from '@shared/enum/profile';
import { environment } from '@env/environment';
import { DkDialogComponent } from '@shared/dk-components/dk-dialog/dk-dialog.component';
import { MaintenanceApiService } from '@shared/apis/maintenance-api.service';

import * as moment from 'moment';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];

  webPushSupported = false;
  webPushIcon = null;
  webPushClass = null;

  auth: any;
  multiCompanies: boolean;

  profileName: string;

  showMaintenance = false;
  maintenances;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private dialog: MatDialog,
    private webPush: NotificationService,
    private maintenanceApiService: MaintenanceApiService,
  ) {
    // Set the defaults
    this.userStatusOptions = [
      {
        'title': 'Online',
        'icon': 'icon-checkbox-marked-circle',
        'color': '#4CAF50'
      },
      {
        'title': 'Away',
        'icon': 'icon-clock',
        'color': '#FFC107'
      },
      {
        'title': 'Do not Disturb',
        'icon': 'icon-minus-circle',
        'color': '#F44336'
      },
      {
        'title': 'Invisible',
        'icon': 'icon-checkbox-blank-circle-outline',
        'color': '#BDBDBD'
      },
      {
        'title': 'Offline',
        'icon': 'icon-checkbox-blank-circle-outline',
        'color': '#616161'
      }
    ];

    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us'
      },
      {
        id: 'tr',
        title: 'Turkish',
        flag: 'tr'
      }
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.auth = this.authService;
    this.profileName = Profile[environment.profile].toLowerCase();
    this.multiCompanies = this.authService.companies() !== null && this.authService.companies().length > 1;

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {'id': this._translateService.currentLang});

    if (this.profileName !== 'prod') {
      this.updateWebPushUI();
      this.webPush.register();
    }


    this.webPush.webPushStatusChanged.subscribe(() => this.updateWebPushUI());

    const maintenanceHide = localStorage.getItem('maintenance.hide');
    this.showMaintenance = !maintenanceHide || maintenanceHide !== moment().format('YYYY-MM-DD');

    this.startMaintenance();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  updateWebPushUI() {
    const status = this.webPush.status;

    this.webPushSupported = status !== this.webPush.statusTypes.NOT_SUPPORTED;
    if (status === this.webPush.statusTypes.SUBSCRIBED) {
      this.webPushIcon = 'notifications';
    } else {
      this.webPushIcon = 'notifications_off';
    }

    if (status === this.webPush.statusTypes.SUBSCRIBED) {
      this.webPushClass = 'green-500-fg';
    } else if (status === this.webPush.statusTypes.DENIED) {
      this.webPushClass = 'red-500-fg';
    } else {
      this.webPushClass = '';
    }
  }

  toggleWebPushSubscription() {
    if (this.webPush.status === this.webPush.statusTypes.SUBSCRIBED) {
      this.webPush.unsubscribe();
    } else if (this.webPush.status === this.webPush.statusTypes.NOT_SUBSCRIBED) {
      this.webPush.subscribe();
    } else if (this.webPush.status === this.webPush.statusTypes.NOT_SUPPORTED) {
      const message = 'Your browser doesn\'t support notification.';
      this.dialogShow('Notification', message);
    } else if (this.webPush.status === this.webPush.statusTypes.ERROR) {
      const message = 'Error while change notification settings.  Please reload your web page.';
      this.dialogShow('Notification', message);
    } else if (this.webPush.status === this.webPush.statusTypes.DENIED) {
      const message = `Please enable notification settings on your browser. `
        + 'Click <a href=\'https://support.google.com/chrome/answer/3220216?co=GENIE.Platform%3DDesktop&hl=en&oco=1\'>here</a> '
        + 'to view help for Chrome.';
      this.dialogShow('Notification', message);
    }
  }

  search(value): void {
    // Do your search here...
    console.log(value);
  }

  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }

  // TODO Check
  selectCompany(company) {
    this.authService.setCompany(company);
    /*this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.router.url]);
    this.router.navigate(['.', {refresh: new Date().getTime()}], {relativeTo: this.route});*/
    window.location.reload();

  }

  clickMyProfile() {
    this.router.navigate(['/my/profile']);
  }

  clickLogout() {
    this.authService.logout();
  }

  dialogShow(title, message, html?) {
    this.dialog.open(DkDialogComponent, {
      data: {
        title: title,
        message: message
      }
    });
  }


  startMaintenance() {
    const maintenanceHide = localStorage.getItem('maintenance.hide');
    const showNotice = !maintenanceHide || maintenanceHide !== moment().format('YYYY-MM-DD');

    this.maintenanceApiService.getMaintenances()
      .subscribe((res: any) => {
          this.maintenances = res.maintenances || [];
          this.showMaintenance = showNotice && this.maintenances && this.maintenances.length;
          if (this.maintenances.length) {
            setTimeout(() => {
              this.hideMaintenance(false);
            }, 15 * 60 * 1000);
          }
        },
        err => {
          console.log(err);
        });
  }

  hideMaintenance(closeToday) {
    if (closeToday) {
      localStorage.setItem('maintenance.hide', moment().format('YYYY-MM-DD'));
    }
    this.showMaintenance = false;
  }
}
