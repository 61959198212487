import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { Observable } from 'rxjs';
import Configs from '../constants/configs';

@Injectable()
export class ReportApiService extends BaseApiService {

  getAgentUsage(from: Date, to: Date, companyId: string,
                groupId: string, offset: number = 0,
                limit: number = Configs.pageSize.default,
                options?): Observable<any> {

    const query = Object.assign({}, {
      from: from ? from.toISOString() : null,
      to: to ? to.toISOString() : null,
      companyId: companyId,
      groupId: groupId,
      offset: offset.toString(),
      limit: limit.toString()
    });

    if (options) {
      Object.assign(query, options);
    }

    return this.http.get(this.baseUrl + '/reports/messages/agents', {params: query});
  }

  getMessages(from: Date, to: Date, companyId: string,
              groupId: string, options?): Observable<any> {

    const query = Object.assign({}, {
      from: from ? from.toISOString() : null,
      to: to ? to.toISOString() : null,
      companyId: companyId,
      groupId: groupId,
      ...options,
    });

    return this.http.get(this.baseUrl + '/reports/messages/messages', {params: query});
  }

  getChatSummary(from: Date, to: Date, companyId: string,
              groupId: string, options?): Observable<any> {

    const query = Object.assign({}, {
      from: from ? from.toISOString() : null,
      to: to ? to.toISOString() : null,
      companyId: companyId,
      groupId: groupId,
      ...options,
    });

    return this.http.get(this.baseUrl + '/reports/messages/chats', {params: query});
  }

  getBatches(): Observable<any> {
    return this.http.get(this.baseUrl + '/batch');
  }

  getSurveys() {
    return this.http.get(`${this.baseUrl}/survey`);
  }

  send(broadcastId, sampleCount) {
    let url = this.baseUrl + '/broadcasts/' + broadcastId + '/send';
    if (sampleCount) {
      url += '?sample=' + sampleCount;
    }
    return this.http.post(url, {});
  }
}
