import * as moment from 'moment';
import 'moment-timezone';
import * as _ from 'lodash';
import Constants from '@shared/constants/constants';
import { DkDialogComponent } from '@shared/dk-components/dk-dialog/dk-dialog.component';
import { environment } from '@env/environment';

export default class Utils {
  static pluralize(noun) {
    if (typeof noun !== 'string') {
      return noun;
    }

    const rules = [
      {regex: /octopus/gi, suffix: 'octopuses'},
      {regex: /person/gi, suffix: 'people'},
      {regex: /ox/gi, suffix: 'oxen'},
      {regex: /goose/gi, suffix: 'geese'},
      {regex: /mouse/gi, suffix: 'mice'},
      {
        regex: /bison|buffalo|deer|duck|fish|moose|pike|plankton|salmon|sheep|squid|swine|trout|sheap|equipment|information|rice|money|species|series|news/i,
        suffix: '$&'
      }, // bison -> bison
      {regex: /(x|ch|ss|sh)$/gi, suffix: '$1es'}, // dish -> dishes, kiss -> kisses
      {regex: /(hetero|canto|photo|zero|piano|pro|kimono|portico|quarto)$/gi, suffix: '$1s'}, // kimono -> kimonos
      {regex: /(?:([^f])fe|([lr])f)$/, suffix: '$1$2ves'}, // knife -> knives, calf -> calves
      {regex: /o$/gi, suffix: 'oes'}, // hero -> heroes
      {regex: /([^aeiouy]|qu)y$/gi, suffix: '$1ies'}, // cherry -> cherries
      {regex: /s$/gi, suffix: 's'}, // cats -> cats
      {regex: /$/gi, suffix: 's'} // cat -> cats
    ];

    for (var i = 0; i < rules.length; i++) {
      const rule = rules[i];
      if (noun.match(rule.regex)) {
        noun = noun.replace(rule.regex, rule.suffix);
        break;
      }
    }

    return noun;
  }

  static getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  static addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  static properCase(str): string {
    if (!str) {
      return str;
    }

    if (str == str.toLowerCase() || str == str.toUpperCase()) {
      return ('' + str.replace(/[^\s\-\']+[\s\-\']*/g, function (word) {
        return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
      }).replace(/\b(Van|De|Der|Da|Von)\b/g, function (nobiliaryParticle) {
        return nobiliaryParticle.toLowerCase();
      }).replace(/Mc(.)/g, function (match, letter3) {
        return 'Mc' + letter3.toUpperCase();
      }));
    } else {
      return str;
    }
  }

  static fullName(object) {
    let ret;
    if (object.hasOwnProperty('firstName') && object.hasOwnProperty('lastName')) {
      ret = Utils.properCase((object.firstName + ' ' + object.lastName).trim());
    } else if (object.hasOwnProperty('firstName')) {
      ret = Utils.properCase(object.firstName);
    } else if (object.hasOwnProperty('lastName')) {
      ret = Utils.properCase(object.lastName);
    } else if (object.hasOwnProperty('username')) {
      ret = object.username;
    }

    return ret;
  }

  static defaultAvatarImage = 'assets/images/avatars/system.png';

  static formatTel(tel, countryCode) {
    if (!tel) {
      return null;
    }

    // remove all except number
    tel = tel.replace(/[^\d]/g, '');

    if (countryCode === 'US' || countryCode === 'CA') {
      switch (tel.length) {
        case 10:
          tel = '1' + tel;
          break;
        case 11:
          break;
        default:
          // not a valid number
          return null;
      }
    }

    if (tel && tel.charAt(0) !== '+') {
      tel = '+' + tel;
    }

    return tel;
  }

  static contentType(content) {
    const image = content.match(/^image\/.*/);
    const video = content.match(/^video\/.*/);
    const isFile = !content.match(/^(image|video)\/.*/);

    let type;
    if (isFile) {
      type = 'file';
    } else if (image !== null) {
      type = 'image';
    } else if (video !== null) {
      type = 'video';
    }

    return type;
  }

  static bytesToSize(bytes: number) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
      return '0 Byte';
    }

    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  }

  static tel(tel) {
    if (!tel) {
      return '';
    }

    const value = tel.toString().trim().replace(/\D/g, '');

    if (value.match(/[^0-9]/)) {
      return tel;
    }

    let country, city, number;

    switch (value.length) {
      case 10: // +1PPP####### -> C (PPP) ###-####
        country = 1;
        city = value.slice(0, 3);
        number = value.slice(3);
        break;

      case 11: // +CPPP####### -> CCC (PP) ###-####
        country = value[0];
        city = value.slice(1, 4);
        number = value.slice(4);
        break;

      case 12: // +CCCPP####### -> CCC (PP) ###-####
        country = value.slice(0, 3);
        city = value.slice(3, 5);
        number = value.slice(5);
        break;

      default:
        return tel;
    }

    if (country === 1) {
      country = '';
    }

    number = number.slice(0, 3) + '-' + number.slice(3);

    return (country + ' (' + city + ') ' + number).trim();
  }

  static getErrorMessage(err) {
    if (typeof err === 'string') {
      return err;
    }
    console.log(err);
    return _.get(err, 'error.message')
      || _.get(err, 'message')
      || _.get(err, 'error')
      || _.get(err, 'statusText')
      || err;
  }

  static hasSupervisorRole(roles) {
    return (roles || []).some(x => {
      return x === Constants.userRole.ADMIN;
    });
  }

  static showError(dialog, err) {
    dialog.open(DkDialogComponent, {
      width: '350px',
      data: {
        title: 'Error',
        message: Utils.getErrorMessage(err),
      }
    });
  }

  static showMessage(dialog, message, title?, width?) {
    dialog.open(DkDialogComponent, {
      width: width || '350px',
      data: {
        title: title || environment.site.name,
        message: message,
      }
    });
  }

  getReportType(from, to) {
    var diff = Math.abs(from.getTime() - to.getTime()) / 3600000;
    if (diff <= 24) {
      return 'h';
    }

    diff = Math.ceil(Math.abs(from.getTime() - to.getTime()) / (1000 * 3600 * 24));
    if (diff <= 14) {
      return 'd';
    }

    diff = to.getMonth() - from.getMonth() + (12 * (to.getFullYear() - from.getFullYear()));
    if (diff <= 18) {
      return 'm';
    }

    return 'y';
  }

  responsiveSize() {
    var w = window.innerWidth;
    if (w < 768) {
      return 'xs';
    } else if (w < 992) {
      return 'sm';
    } else if (w < 1200) {
      return 'md';
    } else {
      return 'lg';
    }
  }

  titleCase(input) {
    input = input || '';
    return input.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  properCase(str) {
    if (!str) {
      return str;
    }

    if (str == str.toLowerCase() || str == str.toUpperCase()) {
      return ('' + str.replace(/[^\s\-\']+[\s\-\']*/g, function (word) {
        return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
      }).replace(/\b(Van|De|Der|Da|Von)\b/g, function (nobiliaryParticle) {
        return nobiliaryParticle.toLowerCase();
      }).replace(/Mc(.)/g, function (match, letter3) {
        return 'Mc' + letter3.toUpperCase();
      }));
    } else {
      return str;
    }
  }

  fullAddress(address, separator) {
    if (!separator) {
      separator = '\n';
    }

    var ret = '';
    if (address.address1) {
      if (ret != '') {
        ret += separator;
      }
      ret += address.address1;
    }

    if (address.address2) {
      if (ret != '') {
        ret += separator;
      }
      ret += address.address2;
    }

    var cityLine = '';
    if (address.city) {
      cityLine += address.city;
    }

    if (address.state) {
      if (cityLine != '') {
        cityLine += ', ';
      }
      cityLine += address.state;
    }
    if (address.zipcode) {
      cityLine += ' ' + address.zipcode;
      cityLine = cityLine.trim();
    }

    if (cityLine != '') {
      if (ret != '') {
        ret += separator;
      }
      ret += cityLine;
    }

    return ret;
  }

  text2Html(text) {
    if (typeof text !== 'string') {
      return text;
    }

    var rules = [
      {regex: /\n/g, replace: '<br />'}
    ];

    for (var i = 0; i < rules.length; i++) {
      var rule = rules[i];
      if (text.match(rule.regex)) {
        text = text.replace(rule.regex, rule.replace);
        break;
      }
    }

    return text;
  }

  // isSearchableSize  (array) {
  //   return (array && array.length >= Config.search.minSize);
  // }

  timezone() {

    //return moment().tz(moment.tz.guess()).format('z');
  }

  fullTimezone() {
    //return moment().tz(moment.tz.guess()).format('z');
  }

  isDate(data) {
    if (data instanceof Date) {
      return true;
    }

    return false;
  }

  isValidPhoneNumber(data) {
    const phoneNumberPattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return phoneNumberPattern.test(data);
  }

  dropTime(datetime) {
    if (datetime instanceof moment) {
      // convert to utc without change time
      var offset = (datetime as any).utcOffset();
      datetime = (datetime as any).utc();
      datetime.add(offset, 'minutes');

      // drop time
      datetime = datetime.startOf('day');
    } else if (datetime instanceof Date) {
      var userTimezoneOffset = datetime.getTimezoneOffset() * 60000;

      // drop time
      datetime = new Date(datetime.getFullYear(), datetime.getMonth(), datetime.getDate());

      // convert to utc without changing time
      datetime = new Date(datetime.getTime() - userTimezoneOffset);
    }

    return datetime;
  }

  tat(date) {
    if (!date) {
      return null;
    }

    if (!moment(date).isValid) {
      return null;
    }

    var start = +new Date(date);
    var now = +new Date();

    var diff = +new Date(now - start);
    var days = Math.floor(diff / 1000 / 60 / 60 / 24);

    return days;
  }

  // utc2LocalDate  (date) {
  //   if (!date) {
  //     return null;
  //   }
  //
  //   if (!moment(date).isValid) {
  //     return null;
  //   }
  //
  //   return moment(date).local().format(Config.format.date);
  // }

  // utc2LocalDateTime  (datetime) {
  //   if (!datetime) {
  //     return null;
  //   }
  //
  //   if (!moment(datetime).isValid) {
  //     return null;
  //   }
  //
  //   return moment(datetime).local().format(Config.format.dateTime);
  // }

}
