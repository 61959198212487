import { Pipe, PipeTransform } from '@angular/core';
import ColorUtils from '@shared/utils/colorUtils';

@Pipe({name: 'color'})
export class ColorPipe implements PipeTransform {

  transform(value: any, type: any): any {
    let color = ColorUtils.stringToColor(value).bgColor;
    if (type === 'fg') {
      color = ColorUtils.stringToColor(value).fgColor;
    }

    return color;
  }

}
