import { Component, OnInit } from '@angular/core';
import {SpinnerService} from '@shared/services/spinner.service';

@Component({
  selector: 'dk-spinner',
  templateUrl: './dk-spinner.component.html',
  styleUrls: ['./dk-spinner.component.scss']
})
export class DkSpinnerComponent implements OnInit {

  constructor(public spinnerService: SpinnerService) { }

  ngOnInit() {
  }

}
