export default class Constants {

  static readonly defaultUser = 'user';
  static readonly adminUser = 'admin';

  static readonly roles = {
    // system roles
    anyone: [],
    user: [Constants.defaultUser],
    admin: [
      'admin',
      'companyAdmin',
    ],
    companyAdmin: [
      'companyAdmin',
    ],
    rptAdmin: [
      'admin',
      'companyAdmin',
      'manager',
      'supervisor',
    ],
    systemAdmin: [
      'systemAdmin',
    ],
    dashboardAdmin: [
      'admin',
      'companyAdmin',
      'manager',
      'supervisor',
    ],
    developer: ['developer'],
    companyImBosAdmin: [
      'companyImBosAdmin',
    ],
    companyImPgrAdmin: [
      'companyImPgrAdmin',
    ],
    companyImRepairAdmin: [
      'companyImRepairAdmin',
    ],
  };

  // userManagement AuthList
  static readonly userRole = {
    AGENT: 'agent',
    ADMIN: 'admin',
  };

  static readonly userRoleList = [
    'agent',
    'admin'
  ];

  static readonly companyFeature = {
    FILE_SHARE: 'FILE_SHARE',
  };

  static readonly shutdownType = {
    READONLY: 'readonly',
    HIDDEN: 'hidden',
    RETIRED: 'retired',
  };


}
