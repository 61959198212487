import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';

@Injectable()
export class AuthApiService extends BaseApiService {

  login(username: string, password: string): Observable<any> {
    const query = {
      username: username,
      password: password
    };

    return this.http.post(this.baseUrl + '/auth/login', query);
  }

  requestCode(email: string): Observable<any> {
    const query = {
      email: email
    };

    return this.http.post(this.baseUrl + '/auth/reset-code/send', query);
  }

  validateCode(email: string, code: string): Observable<any> {
    const query = {
      email: email,
      resetCode: code.toString()
    };

    return this.http.post(this.baseUrl + '/auth/reset-code/validate', query);
  }

  changePassword(email: string, current: string, password: string): Observable<any> {
    const query = {
      email: email,
      current: current,
      password: password,
    };

    return this.http.post(this.baseUrl + '/auth/change-password', query);
  }

  resetPassword(email: string, resetCode: string, password: string): Observable<any> {
    const query = {
      email: email,
      resetCode: resetCode,
      password: password,
    };

    return this.http.post(this.baseUrl + '/auth/reset-password', query);
  }

  refreshToken(refreshToken): Observable<any> {
    const query = {
      refresh_token: refreshToken
    };

    return this.http.post(this.baseUrl + '/auth/refresh-token', query);
  }

}
