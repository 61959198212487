import Utils from '../../../../shared/utils/utils';

export class Company {
  _id: any;
  avatar: string;
  name: string;
  tel: string;
  address: {
    address1: string,
    address2: string,
    city: string,
    state: string,
    zipcode: string,
    country: string,
  };

  constructor(company) {
    {
      this._id = company._id;
      this.avatar = company.avatar || '';
      this.name = company.name || '';
      this.tel = company.tel || '';
      this.address = company.address || {};
    }
  }
}
