import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export abstract class BaseApiService {

  protected baseUrl = environment.api.baseUrl;
  protected http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }
}
