import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { Observable } from 'rxjs';

@Injectable()
export class BroadcastApiService extends BaseApiService {
  getBroadcasts(code) {
    return this.http.get(`${this.baseUrl}/broadcasts/template/${code}`);
  }

  getBroadcastTemplate(templateCode) {
    return this.http.get(`${this.baseUrl}/broadcast-templates/${templateCode}`);
  }

  getBroadcast(id) {
    return this.http.get(`${this.baseUrl}/broadcasts/${id}`);
  }

  createBroadcastGroup(companyId, broadcast) {
    Object.assign(broadcast, {
      company: companyId
    });
    return this.http.post(this.baseUrl + '/broadcasts', {...broadcast});
  }

  updateBroadcastGroup(broadcastId, broadcast) {
    return this.http.patch(this.baseUrl + '/broadcasts/' + broadcastId, {...broadcast});
  }

  downloadBroadcastTemplate(templateId: string, broadcastId: any) {
    const query = {
      broadcastId,
    };
    this.http.get(this.baseUrl + `/excel/download/${templateId}`, {responseType: 'arraybuffer', params: query})
      .subscribe(res => {

        const data = new Blob([res], {type: 'application/vnd.ms-excel'});
        importedSaveAs(data, templateId + '.xlsx');
      });
  }

  send(broadcastId, sampleCount) {
    let url = this.baseUrl + '/broadcasts/' + broadcastId + '/send';
    if (sampleCount) {
      url += '?sample=' + sampleCount;
    }
    return this.http.post(url, {});
  }

  //
  // getBroadcast(companyId: string,
  //              broadcastId: string, status: string, offset: number = 0,
  //              limit: number = Configs.pageSize.default,
  //              options?): Observable<any> {
  //
  //   const query = Object.assign({}, {
  //     companyId: companyId,
  //     broadcastId: broadcastId,
  //     status: status,
  //     offset: offset.toString(),
  //     limit: limit.toString()
  //   });
  //
  //   if (!query.status) {
  //     delete query.status;
  //   }
  //
  //   if (options) {
  //     Object.assign(query, options);
  //   }
  //
  //   return this.http.get(this.baseUrl + '/reports/broadcasts', {params: query});
  // }

  getBroadcastTargets(companyId: string,
                      broadcastId: string, status: string,
                      options?): Observable<any> {
    const query: any = {
      companyId: companyId,
      broadcastId: broadcastId,
    };

    if (status) {
      query.status = status;
    }

    if (options) {
      Object.assign(query, options);
    }

    return this.http.get(this.baseUrl + '/broadcasts/' + broadcastId + '/targets', {params: query});
  }

  getBroadcastGroups(companyId: string,
                     templateCode: any,
                     options?): Observable<any> {

    const query: any = Object.assign({}, {
      companyId: companyId,
    });

    if (templateCode) {
      query.templateCode = templateCode;
    }

    if (options) {
      Object.assign(query, options);
    }

    return this.http.get(this.baseUrl + '/broadcasts/groups', {params: query});
  }

}
