import { Injectable } from '@angular/core';
import * as shape from 'd3-shape';

@Injectable()
export class ChartSettingService {

  widgetData = [
    {
      title: 'Sent',
      item: 'sent',
      label: 'Message',
      color: 'light-blue-fg',
      count: 0,
      secondary: []
    },
    {
      title: 'Received',
      item: 'received',
      label: 'Message',
      color: 'red-fg',
      count: 0,
      secondary: []
    },
    {
      title: 'Customer',
      item: 'customer',
      label: 'Customer',
      color: 'orange-fg',
      count: 0,
      secondary: []
    },
    {
      title: 'Agent',
      item: 'agent',
      label: 'Agent',
      color: 'blue-grey-fg',
      count: 0,
      secondary: []
    },
  ];

  widget5 = {
    xAxis: true,
    yAxis: true,
    gradient: false,
    legend: true,
    legendTitle: '',
    showXAxisLabel: false,
    xAxisLabel: '',
    showYAxisLabel: false,
    yAxisLabel: 'Isues',
    scheme: {
      domain: ['#42BFF7', '#f44336', '#C7B42C', '#AAAAAA']
    },
    onSelect: (ev) => {
      console.log(ev);
    },
    supporting: {
      xAxis: false,
      yAxis: false,
      gradient: false,
      legend: false,
      showXAxisLabel: false,
      xAxisLabel: '',
      showYAxisLabel: false,
      yAxisLabel: 'Isues',
      scheme: {
        domain: ['#42BFF7', '#f44336', '#C7B42C', '#AAAAAA']
      },
      curve: shape.curveBasis
    }
  };

  pieChart = {
    legend: true,
    legendTitle: '',
    explodeSlices: false,
    labels: true,
    doughnut: true,
    gradient: false,
    scheme: {
      domain: ['#f44336', '#9c27b0', '#03a9f4', '#1ee963']
    },
    onSelect: (ev) => {
      //console.log(ev);
    }
  };


}
