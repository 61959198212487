import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ProfileApiService } from '../../../../shared/apis/profile-api.service';

@Injectable()
export class NotificationService {

  webPushStatusChanged: EventEmitter<string> = new EventEmitter<string>();

  readonly statusTypes = {
    UNKNOWN: 'unknown',
    ERROR: 'error',
    NOT_SUPPORTED: 'not_supported',
    DENIED: 'denied',
    NOT_SUBSCRIBED: 'not_subscribed',
    SUBSCRIBED: 'subscribed'
  };

  status = this.statusTypes.UNKNOWN;

  swRegistration: any;

  constructor(private profileApiService: ProfileApiService) {
  }

  requestPermission() {
    return Notification.requestPermission();
  }

  register() {
    if ('serviceWorker' in window.navigator && 'PushManager' in window) {
      this.requestPermission().then((permission) => {
        if (permission === 'denied') {
          this.changeStatus(this.statusTypes.DENIED);
          return;
        }

        window.navigator.serviceWorker.register('assets/js/sw.js')
          .then((swReg) => {
            this.swRegistration = swReg;
            this.swRegistration.update();

            this.swRegistration.pushManager.getSubscription()
              .then((subscription) => {

                if (subscription != null) {
                  this.changeStatus(this.statusTypes.SUBSCRIBED);
                } else {
                  if (localStorage.getItem('didAskNotification')) {
                    this.changeStatus(this.statusTypes.NOT_SUBSCRIBED);
                  } else {
                    this.subscribe();
                    localStorage.setItem('didAskNotification', 'true');
                  }
                }
              })
              .catch((error) => {
                this.changeStatus(this.statusTypes.ERROR);
              });
          });
      });
    }
  }

  subscribe() {
    const applicationServerKey = this._urlB64ToUint8Array(environment.webPush.publicKey);

    this.swRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: applicationServerKey
    }).then(subscription => {
      this.profileApiService.subscribeWebPush(JSON.stringify(subscription))
        .subscribe(() => {
          this.changeStatus(this.statusTypes.SUBSCRIBED);
        }, () => {
          this.changeStatus(this.statusTypes.ERROR);
        });
    }).catch(err => {
      console.log('Failed to subscribe the user: ', err);
    });
  }

  unsubscribe() {
    this.swRegistration.pushManager.getSubscription()
      .then(subscription => {
        if (subscription) {
          subscription.unsubscribe();
          this.profileApiService.unsubscribeWebPush(JSON.stringify(subscription))
            .subscribe(() => {
              this.changeStatus(this.statusTypes.NOT_SUBSCRIBED);
            }, () => {
              this.changeStatus(this.statusTypes.ERROR);
            });
        }
      }).catch(err => {
      console.log('Error unsubscribing', err);
    });
  }

  _urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  changeStatus(value) {
    if (this.status !== value) {
      this.status = value;
      this.webPushStatusChanged.emit(this.status);
    }
  }
}
