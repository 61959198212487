import { Pipe, PipeTransform } from '@angular/core';
import Utils from '../utils/utils';

@Pipe({ name: 'displayName' })
export class DisplayNamePipe implements PipeTransform {
  transform(object: any) {
    let ret = object.firstName || object.lastName;
    if (ret) {
      return Utils.properCase(ret);
    }
    ret = object.username;
    if (ret) {
      return ret.substring(0, ret.lastIndexOf('@'));
    }

    return null;
  }
}
