import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import Configs from '@shared/constants/configs';
import { Observable } from 'rxjs';

@Injectable()
export class DashboardApiService extends BaseApiService {

  getMessageDashboard(from, to, companyId, groupId, options?) {
    const query = {
      from: from ? from.toISOString() : null,
      to: to ? to.toISOString() : null,
      companyId: companyId,
      groupId: groupId,
    };

    if (options) {
      Object.assign(query, options);
    }

    return this.http.get(this.baseUrl + '/reports/messages/dashboard',
      {params: query});
  }

  getGroupSummary(from: Date, to: Date, companyId: string,
                  options = {}): Observable<any> {

    const query = {
      from: from ? from.toISOString() : null,
      to: to ? to.toISOString() : null,
      companyId: companyId,
      ...options,
    };

    return this.http.get(this.baseUrl + '/reports/messages/groups', {params: query});
  }
}
