import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { Company } from '../../main/apps/admin/company/company.model';
import { FuseUtils } from '@fuse/utils';

@Injectable()
export class CompanyApiService extends BaseApiService implements Resolve<any> {

  companies: Company[];
  onCompaniesChanged: BehaviorSubject<any> = new BehaviorSubject([]);
  onSearchTextChanged: Subject<any> = new Subject();

  searchText: string;

  /**
   * Resolver
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getCompanies()
      ]).then(() => {
          this.onSearchTextChanged.subscribe(searchText => {
            this.searchText = searchText;
            this.getCompanies();
          });
          resolve(null);
        }, reject);
    });
  }

  getCompanies(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + '/master/companies')
        .subscribe((response: any) => {
          this.companies = response;

          if (this.searchText && this.searchText !== '') {
            this.companies = FuseUtils.filterArrayByString(this.companies, this.searchText, 'name');
          }

          this.companies = this.companies.map(company => {
            return new Company(company);
          });

          this.onCompaniesChanged.next(this.companies);
          resolve(this.companies);
        }, reject);
    });
  }

  createCompany(company) {
    return this.http.post(this.baseUrl + '/master/companies/', {...company})
      .subscribe(() => this.getCompanies());
  }

  updateCompany(id, company) {
    return this.http.patch(this.baseUrl + '/master/companies/' + id, {...company})
      .subscribe(() => this.getCompanies());
  }

}
