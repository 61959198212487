import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Router } from '@angular/router';
import { AuthApiService } from '@shared/apis/auth-api.service';
import Constants from '@shared/constants/constants';
import { EMPTY } from 'rxjs';

@Injectable()
export class AuthService {

  token: any = null;
  roles: Array<string> = [];

  readonly tokenKeyName = 'token';

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  constructor(private authApiService: AuthApiService,
              private router: Router) {
  }

  requestResetCode(email: string): Observable<Object> {
    return this.authApiService.requestCode(email);
  }

  validateCode(email: string, code: string): Observable<Object> {
    return this.authApiService.validateCode(email, code);
  }

  changePassword(email: string, currentPassword: string, password: string): Observable<Object> {
    return this.authApiService.changePassword(email, currentPassword, password);
  }

  resetPassword(email: string, resetCode: string, password: string): Observable<Object> {
    return this.authApiService.resetPassword(email, resetCode, password);
  }

  login(username: string,
        password: string,
        rememberMe: boolean = false): Observable<boolean> {

    return this.authApiService.login(username, password)
      .pipe(
        map(result => {
          this.setToken(result, rememberMe);
          return true;
        })
      );
  }

  logout(): void {
    this.setToken(null);
    this.router.navigate(['/auth/login']);
  }

  isAuthenticated(): boolean {
    return this.getToken() !== null;
  }

  companies() {
    const token = this.getToken();
    return token && token.user ? token.user.companies : null;
  }

  setCompany(company) {
    const token = this.getToken();
    token.company = null;

    for (const c of token.user.companies) {
      if (c._id === company._id) {
        token.company = c;
        this.saveToken(token);
        this.processAuthority();
        break;
      }
    }
  }

  hasCompanyFeature(feature) {
    // return true if company has a feature
    const company = this.company();
    return company && company.features && company.features.includes(feature);
  }

  userId() {
    const token = this.getToken();
    return token && token.user ? token.user._id : null;
  }

  userName() {
    const token = this.getToken();
    return token && token.user ? token.user.username : null;
  }

  company() {
    const token = this.getToken();
    return token ? token.company : null;
  }

  avatar() {
    const token = this.getToken();
    if ((token && token.user) && token.user.avatar) {
      return token.user.avatar;
    }
    return null;
  }

  displayName() {
    const token = this.getToken();
    if (token && token.user) {
      let ret = token.user.firstName || token.user.lastName;
      if (ret) {
        return ret;
      }

      ret = token.user.username;
      return ret.substring(0, ret.lastIndexOf('@'));
    }
    return null;
  }

  getToken() {
    if (this.token) {
      return this.token;
    }

    let tokenStr = sessionStorage.getItem(this.tokenKeyName);
    let token = null;

    if (!tokenStr) {
      // load if token exists in local session
      tokenStr = localStorage.getItem(this.tokenKeyName);
    }
    if (tokenStr) {
      token = JSON.parse(tokenStr);
      this.processToken(token);
    }

    return token;
  }

  getAccessToken(): string {
    const token = this.getToken();
    return token ? token.accessToken : '';
  }

  setToken(token, rememberMe?: boolean): void {
    if (token) {
      // set company
      token.company = null;

      if (token.user && token.user.companies && token.user.companies.length > 0) {
        if (token.user.companies.length === 1) {
          // one company
          token.company = token.user.companies[0];
        } else {
          // multi company
          // save company before replace token
          const company = this.company();
          if (company) {
            for (let c of token.user.companies) {
              if (c._id === company._id) {
                token.company = c;
                break;
              }
            }
          }
        }
      }

      // let's save
      this.saveToken(token, rememberMe);

    } else {
      sessionStorage.removeItem(this.tokenKeyName);
      localStorage.removeItem(this.tokenKeyName);
      window.location.reload();
    }

    this.processToken(token);
  }

  private saveToken(token, rememberMe?: boolean): void {
    if (!token) {
      return;
    }

    if (!rememberMe) {
      if (localStorage.getItem(this.tokenKeyName)) {
        // check previously token was saved on localStorage
        // if yes, save to localStorage to keep the persistency
        rememberMe = true;
      }
    }

    const tokenStr = JSON.stringify(token);

    if (rememberMe) {
      localStorage.setItem(this.tokenKeyName, tokenStr);
      sessionStorage.removeItem(this.tokenKeyName);
    } else {
      sessionStorage.setItem(this.tokenKeyName, tokenStr);
      localStorage.removeItem(this.tokenKeyName);
    }
  }

  private processToken(token): void {
    this.token = token;
    this.roles = [];

    this.processAuthority();
  }

  private processAuthority() {
    const roles = [];
    const token = this.token;

    if (token) {
      // add default user for logged in user
      roles.push(Constants.defaultUser);

      if (token && token.company) {
        token.company.groups.forEach(group => {
          if (group.roles) {
            group.roles.forEach(role => {
              if (roles.filter(x => x === role).length === 0) {
                roles.push(role);
              }
            });
          }
        });

        if (token.company.authorities) {
          for (let i = 0; i < token.company.authorities.length; i++) {
            const authority = token.authorities[i];
            roles.push(authority.authority);
          }
        }

        // add company roles
        if (token.user.companies) {
          token.user.companies.forEach(company => {
            if (company._id === token.company._id) {
              if (company.roles) {
                company.roles.forEach(role => {
                  if (!roles.includes(role)) {
                    roles.push(role);
                  }
                });
              }
            }
          });
        }

        // add system roles
        if (token.user.systemRoles) {
          token.user.systemRoles.forEach(role => {
            if (!roles.includes(role)) {
              roles.push(role);
            }
          });
        }
      }
    }
    this.roles = roles;
  }

  hasRole(roles) {
    if (!this.roles && !this.roles.length) {
      return false;
    }

    if (!(roles instanceof Array)) {
      roles = [roles];
    }

    const intersect = roles.filter(x => {
      return this.roles.indexOf(x) !== -1;
    });

    return intersect.length > 0;
  }

  refreshToken(): Observable<any> {
    const token = this.getToken();

    if (!token || !token.refreshToken) {
      return EMPTY;
    }

    return this.authApiService.refreshToken(token.refreshToken);
  }

  get amISystemAdmin(): boolean {
    return this.getToken().user.systemRoles.some(f => Constants.roles.systemAdmin.includes(f));
  }
}
