import { Pipe, PipeTransform } from '@angular/core';
import Utils from '../utils/utils';

@Pipe({ name: 'fullName' })
export class FullNamePipe implements PipeTransform {
  transform(object: any) {
    if (!object) {
      return null;
    }

    return Utils.fullName(object);
  }
}
