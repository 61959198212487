import { SocketIoConfig, Socket } from 'ngx-socket-io';
import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import { PusherChannel } from './pusherChannel';

export enum DkPusherDebugLevel {
  trace = 'trace',
  debug = 'debug',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

export interface DkPusherOptions {
  key: string;
  url?: string;
  cluster?: string;
  encrypted?: boolean;
  debug?: DkPusherDebugLevel;
  options?: object;
  error?: any;
  username?: any;
}

const HB_INTERVAL = 60000; // every minutes

export class DkPusher {
  public socket: Socket;
  private channels = {};
  private debugLevels;
  private debugLevel;
  private opts: DkPusherOptions;
  private http;

  constructor(opts: DkPusherOptions) {
    // prepare debug level
    this.debugLevels = Object.keys(DkPusherDebugLevel).reduce((sum, level, idx) => {
      sum[level] = idx;
      return sum;
    }, {});

    this.debugLevel = this.debugLevels[opts.debug || DkPusherDebugLevel.error];
    this.opts = opts;

    this.http = new HttpClient(new HttpXhrBackend({build: () => new XMLHttpRequest()}));
    this.connect();
  }

  subscribe(channel): PusherChannel {
    if (!this.channels[channel]) {
      this.channels[channel] = new PusherChannel(channel);
      if (channel.startsWith('private-')) {
        this.getAuth(this.socket.ioSocket.id, channel).subscribe((res: any) => {
          this.socket.emit('subscribe', {channel, auth: res.auth});
        }, err => {
          this.logClientEvent(DkPusherDebugLevel.error, 'auth error', err);
        });
      } else {
        this.socket.emit('subscribe', {channel});
      }
    }
    return this.channels[channel];
  }

  unsubscribe(channel) {
    if (!this.channels[channel]) {
      return;
    }
    this.socket.emit('unsubscribe', {channel});
    this.channels[channel].unbind();
    delete this.channels[channel];
  }

  private connect() {
    this.logClientEvent(DkPusherDebugLevel.debug, `connecting:  ${this.opts.url}`);
    if (!this.opts.url) {
      if (window.location.protocol === 'http') {
        this.opts.url = `ws://${window.location.host}`;
      } else {
        this.opts.url = `wss://${window.location.host}`;
      }
    }

    const config: SocketIoConfig = {url: this.opts.url, options: {...this.opts.options, transports: ['websocket']}};
    if (!config.options.path) {
      config.options.path = '/socket.io';
    }
    this.socket = new Socket(config);

    this.socket.on('connect', () => {
      this.logClientEvent(DkPusherDebugLevel.debug, `connected:  ${this.opts.url}`);
    });

    this.socket.on('auth', () => {
      this.logClientEvent(DkPusherDebugLevel.debug, `sending auth: ${this.opts.key}`);
      this.socket.emit('auth', {key: this.opts.key});
    });

    this.socket.on('welcome', message => {
      this.logClientEvent(DkPusherDebugLevel.debug, `got welcome: ${message}`);
      // reconnect channels
      Object.keys(this.channels).forEach(channel => {
        if (this.channels[channel]) {
          this.socket.emit('subscribe', {channel});
        }
      });

      // this.heartBeat();
    });

    this.socket.on('data', obj => {
      const {channel, event, data} = obj;
      const pusherChannel = this.channels[channel];
      if (!pusherChannel) {
        return;
      }
      this.logClientEvent(DkPusherDebugLevel.trace, 'got message:', {event, data});
      pusherChannel.handleEvent(event, data);
    });

    this.socket.on('terminated', message => {
      this.logClientEvent(DkPusherDebugLevel.debug, `terminated: ${message}`);
    });

    this.socket.on('error', message => {
      this.logClientEvent(DkPusherDebugLevel.error, `error: ${message}`);
    });

    this.socket.on('disconnect', message => {
      this.logClientEvent(DkPusherDebugLevel.debug, `disconnected: ${message}`);
    });

    this.socket.on('connect_error', message => {
      this.logClientEvent(DkPusherDebugLevel.error, `connect_error: ${message}`);
    });

    this.socket.connect();
  }

  private logClientEvent(level, message, data = null) {
    if (this.debugLevel > this.debugLevels[level]) {
      return;
    }
    console.log(`[DK Pusher] ${message}`, data || '');

    if (level === DkPusherDebugLevel.error) {
      if (this.opts.error) {
        this.opts.error(message, data);
      }
    }
    const body = {
      key: this.opts.key,
      level,
      log: message,
      data: {
        ...data,
        ...this.opts,
      },
    };
    try {
      this.http.post(`${environment.api.baseUrl}/pusher/log`, body).subscribe();
    } catch (err) {
      // suppress error
    }
  }

  private getAuth(socketId, channelName): Observable<any> {
    return this.http.post(`${environment.api.baseUrl}/pusher/auth`, {socketId, channelName});
  }

  private heartBeat() {
    this.socket.emit('hb');
    setTimeout(() => {
      this.heartBeat();
    }, HB_INTERVAL);
  }
}
