import { Component, OnInit } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from '@auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss'],
  animations: fuseAnimations
})
export class SelectCompanyComponent implements OnInit {

  companies = [];

  constructor(private fuseConfig: FuseConfigService,
              private authService: AuthService,
              private router: Router) {

    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.companies = authService.companies();
  }

  ngOnInit() {
  }

  selectCompany(company) {
    this.authService.setCompany(company);

    const redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '';
    this.router.navigate([redirect]);
  }
}
