import { NgModule } from '@angular/core';
import { DkDialogComponent } from './dk-dialog/dk-dialog.component';
import { DkUploadComponent } from '@shared/dk-components/dk-upload/dk-upload.component';
import { FuseSharedModule } from '../../../@fuse/shared.module';
import { DkSpinnerComponent } from '@shared/dk-components/dk-spinner/dk-spinner.component';

@NgModule({
  declarations: [
    DkDialogComponent,
    DkUploadComponent,
    DkSpinnerComponent
  ],
  imports: [
    FuseSharedModule,
  ],
  entryComponents: [
    DkDialogComponent,
    DkUploadComponent
  ],
  exports: [
    DkSpinnerComponent
  ]
})
export class DkComponentModule {
}
