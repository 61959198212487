export default class ColorUtils {

  static stringToColor(string) {
    let hash = 0, i, chr;

    if (string) {
      for (i = 0; i < string.length; i++) {
        chr = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash = hash & hash; // Convert to 32bit integer
      }
    }

    const r = (hash >> 16) & 0xff;
    const g = (hash >> 8) & 0xff;
    const b = (hash >> 16) & 0xff;

    const bgColor = '#' + [r, g, b].map(x => {
      const hex = x.toString(16)
      return hex.length === 1 ? '0' + hex : hex
    }).join('');

    const fgColor = this.textColor(r, g, b);

    return {
      fgColor,
      bgColor,
    };
  }

  static textColor(r, g, b) {
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    let fgColor = '#000000';

    if (luma < 160) { // default 40
      // pick a different colour
      fgColor = '#ffffff';
    }

    return fgColor;
  }

}
