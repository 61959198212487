import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { timeout } from 'rxjs/operators';

@Injectable()
export class UserApiService extends BaseApiService {

  getCompany(companyId) {

    return this.http.get(this.baseUrl + '/master/companies/' + companyId);
  }

  getChatGroups(select = null): Observable<any> {
    const opts: any = {};
    if (select) {
      opts.params = {
        select,
      };
    }
    return this.http.get(`${this.baseUrl}/chat/groups`, opts);
  }

  getGroupInfo(groupId) {
    return this.http.get(this.baseUrl + '/groups/' + groupId);
  }

  createGroup(group) {
    return this.http.post(this.baseUrl + '/groups/', {...group});
  }

  updateGroup(groupId, group) {
    return this.http.patch(this.baseUrl + '/groups/' + groupId, {...group});
  }

  createLine(groupId) {
    return this.http.post(this.baseUrl + '/groups/' + groupId + '/lines', {});
  }

  addLine(groupId, tel) {
    return this.http.post(this.baseUrl + '/groups/' + groupId + '/lines', {
      tel,
    });
  }

  deleteLine(groupId, sid) {
    return this.http.delete(this.baseUrl + '/groups/' + groupId + '/lines/' + sid, {});
  }

  getUsers(query) {
    return this.http.get(`${this.baseUrl}/master/users`, {params: query});
  }

  getUser(id) {
    return this.http.get(this.baseUrl + '/master/users/' + id);
  }

  createUser(user) {
    return this.http.post(this.baseUrl + '/master/users/', {...user});
  }

  updateUser(id, user) {
    return this.http.patch(this.baseUrl + '/master/users/' + id, {...user});
  }

  getTemplate(templateId: string) {
    this.http.get(this.baseUrl + `/excel/download/${templateId}`, {responseType: 'arraybuffer'})
      .subscribe(res => {

        const data = new Blob([res], {type: 'application/vnd.ms-excel'});
        importedSaveAs(data, templateId + '.xlsx');
      }, err => {
        // TODO Error dialog
        console.log(err);
      });
  }

  getDownloadUser(groupInfo: any) {
    this.http.get(this.baseUrl + `/excel/download/group/${groupInfo._id}`, {responseType: 'arraybuffer'})
      .subscribe(res => {

        const data = new Blob([res], {type: 'application/vnd.ms-excel'});
        importedSaveAs(data, groupInfo.name + '.xlsx');
      }, err => {
        // TODO Error dialog
        console.log(err);
      });
  }

  fileUpload(formData, templateId): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    const options = {
      headers: headers,
      reportProgress: true,
    };

    // change post for progress bar
    const uploadReq = new HttpRequest('POST', this.baseUrl + `/excel/upload/${templateId}`, formData, options);

    return this.http.request(uploadReq).pipe(timeout(300000));
  }
}
