import {AfterViewInit, Component, ElementRef, Inject, NgZone, OnChanges, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../main/apps/auth/shared/auth.service';
import {UserApiService} from '../../apis/user-api.service';
import {FormControl} from '@angular/forms';
import {finalize, tap} from 'rxjs/operators';
import {HttpEventType} from '@angular/common/http';
import {DkDialogComponent} from '../dk-dialog/dk-dialog.component';
import {SpinnerService} from '@shared/services/spinner.service';

@Component({
  selector: 'dk-upload',
  templateUrl: './dk-upload.component.html',
  styleUrls: ['./dk-upload.component.scss']
})
export class DkUploadComponent implements OnInit, OnChanges {
  protected baseUrl = environment.api.baseUrl;

  dialogTitle: string;
  message: FormControl = new FormControl('');
  dialogMessage: any;

  templateId: any;

  formData: any = {
    filename: null,
  };

  files: any[] = [];

  key: any;
  uploadProgress = 0;
  uploading = false;

  showDropzone = false;

  @ViewChild('dropzone') dropzone: ElementRef;
  @ViewChild('imgFileInput') imgFileInput: ElementRef;

  constructor(public dialogRef: MatDialogRef<DkUploadComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any,
              private dialog: MatDialog,
              private spinnerService: SpinnerService,
              private userApiService: UserApiService,
              private authService: AuthService,
              private ngZone: NgZone) {
    this.dialogTitle = 'Upload';
    this.templateId = this.data.templateId;
  }

  ngOnInit() {
    const msgTitle = 'Data has been successfully uploaded.  Once I process, you will receive some sample email for the verification.';
    const hasRole = this.authService.getToken().user.systemRoles.filter(f => f === 'systemAdmin')[0];
    this.initForm();
  }

  initForm() {
    this.files = [];
    this.formData.filename = null;
    //this.message = new FormControl('');
  }

  templateDownload(templateId) {
    this.userApiService.getTemplate(templateId);
  }

  inDrag(value) {
    this.showDropzone = value;

    if (value) {
      setTimeout(_ => {
        this.ngZone.runOutsideAngular(() => {
          if (this.dropzone) {
            this.dropzone.nativeElement.addEventListener('dragover', ($event) => {
              $event.preventDefault();
              return false;
            });
          }
        });
      }, 150);
    } else {
      setTimeout(_ => {
        this.showDropzone = value;
      }, 100);
    }
  }

  drop(ev) {
    ev.preventDefault();
    this.fileSet(ev.dataTransfer.files);

    this.showDropzone = false;
  }

  fileSelected(event) {
    this.fileSet(event.target.files);
  }

  fileSet(files) {
    this.files = files;

    /*if (this.files.length > 0) {
      const overLimit = Array.from(this.files).some((f: any) => {
        return (f.size > this.configs.fileLimit.limit);
      });

      if (overLimit) {
        const msg = {
          message: 'The file is too large. <br> Please select size 20MB or less.'
        };

        this.imgFileInput.nativeElement.value = '';
        this.seaService.handleError(msg);
        return false;
      }
    }*/

    const file = files[0];
    this.formData.filename = file.name;
  }

  upload(templateId) {
    const formData: FormData = new FormData();
    const file = this.files[0];
    const options = this.data.options;

    formData.append('file', file, file.name);
    formData.append('options', options);
    /*formData.append('message', this.message.value);*/

    this.spinnerService.start();
    this.uploading = true;
    this.userApiService.fileUpload(formData, templateId).pipe(
      finalize(() => {
        this.spinnerService.stop();
        this.uploading = false;
        this.initForm();
      }))
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total) {
            this.uploadProgress = (event.loaded / event.total) * 100;
          } else {
            this.uploadProgress = 0;
          }
        } if (event.type === HttpEventType.Response) {
          this.dialogShow('Success', 'Recipients were uploaded successfully.');
          this.dialogMessage = 'Success';
          if (this.dialogMessage) {
            this.dialogRef.close(this.dialogMessage);
          }
        }
      }, err => {
        this.dialogShow('Fail', 'try again.');
        this.dialogMessage = 'Fail';
      });

  }

  dialogShow(title, message) {
    this.dialog.open(DkDialogComponent, {
      data: {
        title: title,
        message: message
      }
    });

  }

  ngOnChanges() {

  }

}
