export default class Configs {

  static readonly chat = {
    pageSize: 250,
    thumbSize: 300,
  };

  static readonly pageSize = {
    default: 25,
    options: [10, 25, 50, 100],
    filterDelay: 250,
    listAll: -1
  };

  static readonly dateFormat = {
    date: 'MM/DD/YYYY',
    dateTime: 'MM/DD/YYYY h:mm a',
    fullDateTime: 'LLL',
    dateTimeHH: 'MM/DD/YYYY HH:mm',
    dateYYYYMMDD: 'YYYYMMDD',
  };

  static readonly spinnerConfig = {
    color: 'accent',
    mode: 'indeterminate',
    value: 50
  };

  static readonly fileLimit = {
    limit: 1024 * 20 * 1024 // 20MB
  };

  static readonly ranges = {
    TD: 'Today',
    YD: 'Yesterday',
    TW: 'This Week',
    LW: 'Last Week',
    TM: 'This Month',
    LM: 'Last Month',
    CT: 'Custom',
  };

  static readonly agentListOrder = {
    desc: 'Most Frequent',
    asc: 'Least Frequent'
  };

  static readonly refNoRestriction = {
    SEA: {
      ticket_no: {
        required: true,
        maxLength: 10,
        pattern: '^\\d{10}$'
      },
    },
  };

  static readonly version = require('../../../../package.json').version;
  static readonly package = require('../../../../package.json');
}
