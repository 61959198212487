import { ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from '@auth/auth.service';
import { AuthGuardService } from '@auth/auth-guard.service';
import { AuthRequestInterceptor } from '@auth/auth-request.interceptor';
import { AuthResponseInterceptor } from '@auth/auth-response.interceptor';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SecurityCodeComponent } from './security-code/security-code.component';
import { SelectCompanyComponent } from './select-company/select-company.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { StrongPasswordModule } from '@shared/dk-components/dk-strong-password/dk-strong-password.module';

const routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'select-company',
    component: SelectCompanyComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent
  },
  {
    path: 'security-code',
    component: SecurityCodeComponent
  }
];

@NgModule({
  imports: [
    FuseSharedModule,
    StrongPasswordModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    LoginComponent,
    SelectCompanyComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    SecurityCodeComponent
  ]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<FuseSharedModule> {
    return {
      ngModule: FuseSharedModule,
      providers: [
        AuthService,
        AuthGuardService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthRequestInterceptor,
          multi: true
        }, {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthResponseInterceptor,
          multi: true
        }]
    };
  }

}
