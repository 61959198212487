import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { timeout } from 'rxjs/operators';

@Injectable()
export class ChatApiService extends BaseApiService {

  getChatGroups() {
    return this.http.get(`${this.baseUrl}/chat/groups?format=short`);
  }

  getChatGroup(groupId) {
    return this.http.get(`${this.baseUrl}/chat/groups/${groupId}`);
  }

  getRefTypes(groupId) {
    return this.http.get(`${this.baseUrl}/chat/groups/${groupId}/ref-types`);
  }

  populate(groupId, refType, refNo) {
    const data = {
      refType: refType,
      refNo: refNo,
    };

    return this.http.post(`${this.baseUrl}/chat/groups/${groupId}/populate`, data);
  }

  createChatRoom(groupId, data) {
    return this.http.post(`${this.baseUrl}/chat/groups/${groupId}/rooms`, data);
  }

  getChatRooms(groupId, query) {
    return this.http.get(`${this.baseUrl}/chat/groups/${groupId}/rooms`, { params: query });
  }

  getChatRoom(groupId, roomId) {
    return this.http.get(`${this.baseUrl}/chat/groups/${groupId}/rooms/${roomId}`);
  }

  updateChatRoom(groupId, roomId, data) {
    return this.http.patch(`${this.baseUrl}/chat/groups/${groupId}/rooms/${roomId}`, data);
  }

  getMessages(groupId, roomId, limit, cutOff) {
    const query: any = {};

    if (limit) {
      query.limit = limit;
    }
    if (cutOff) {
      const val = {
        'time': {
          $lt: cutOff
        }
      };
      query.query = JSON.stringify(val);
    }

    return this.http.get(
      `${this.baseUrl}/chat/groups/${groupId}/rooms/${roomId}/messages`, { params: query });
  }

  sendMessage(groupId, roomId, message) {
    const data = {
      message: message
    };

    return this.http.post(
      `${this.baseUrl}/chat/groups/${groupId}/rooms/${roomId}/messages`, data);
  }

  getSurveys(groupId) {
    return this.http.get(`${this.baseUrl}/chat/groups/${groupId}/surveys`);
  }

  getSurvey(groupId, roomId, responseId) {
    return this.http.get(
      `${this.baseUrl}/chat/groups/${groupId}/rooms/${roomId}/surveys/${responseId}`);
  }

  createSurvey(groupId, roomId, data) {
    return this.http.post(
      `${this.baseUrl}/chat/groups/${groupId}/rooms/${roomId}/surveys/`, data);
  }

  updateSurvey(groupId, roomId, responseId, data) {
    return this.http.patch(
      `${this.baseUrl}/chat/groups/${groupId}/rooms/${roomId}/surveys/${responseId}`, data);
  }

  getPreviousChats(companyId, chatRoom) {
    const url = `${this.baseUrl}/chat/find-rooms?`
      + `companyId=${companyId}&chatRoomId=${chatRoom._id}&tel=${encodeURIComponent(chatRoom.tel)}`;
    return this.http.get(url);
  }

  getSaveLocations(groupId) {
    return this.http.get(`${this.baseUrl}/chat/groups/${groupId}/save-locations`);
  }

  saveFile(groupId, roomId, messageId, fileId, location) {
    const data = {
      fileId: fileId,
      location: location,
    };

    return this.http.post(`${this.baseUrl}/chat/groups/${groupId}/rooms/${roomId}/messages/${messageId}/save-file`, data)
      .pipe(timeout(300000));
  }

  uploadFile(groupId, roomId, formData) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');


    const uploadReq = new HttpRequest('POST', `${this.baseUrl}/chat/groups/${groupId}/rooms/${roomId}/messages`, formData, {
      headers,
      reportProgress: true,
    });

    return this.http.request(uploadReq).pipe(timeout(300000));
  }

  updateMessage(groupId, roomId, messageId, data) {

    return this.http.patch(`${this.baseUrl}/chat/groups/${groupId}/rooms/${roomId}/messages/${messageId}`, data)
      .pipe();
  }
}
