import { environment } from '@env/environment';
import { DkPusher, DkPusherDebugLevel, DkPusherOptions } from '@shared/services/dk-pusher';
import { Profile } from '@shared/enum/profile';
import { Injectable } from '@angular/core';

declare var Pusher: any;

const MAX_ERROR = 5;

@Injectable()
export class PusherService {
  pusher: any;

  constructor() {

    let useDkPusher = false;
    let username;

    const tokenStr = sessionStorage.getItem('token') || localStorage.getItem('token');
    if (tokenStr) {
      const token = JSON.parse(tokenStr) || {};
      username = (token.user || {}).username;

      const pusherTesters = [
        // 'admin@dkrabbit.com',
        // 'brandon@dkrabbit.com',
        // 'albert.kim@nenix.com',
      ];
      useDkPusher = (pusherTesters.includes(username));
    }

    if (!useDkPusher) {
      this.connectPusher();
      return;
    }

    const debug = environment.profile === Profile.Prod ? DkPusherDebugLevel.error : DkPusherDebugLevel.debug;
    let errorCount = 0;
    const opts: DkPusherOptions = {
      ...environment.pusher,
      debug,
      username,
      error: message => {
        errorCount += 1;
        console.log(`[DK Pusher] error ${errorCount}`, message);
        if (errorCount === MAX_ERROR) {
          console.log('[DK Pusher] switching to pusher.com', message);
          this.pusher.socket.disconnect();
          this.connectPusher();
        }
      }
    };

    this.pusher = new DkPusher(opts);
    if (debug <= DkPusherDebugLevel.debug) {
      console.log('[DK Pusher] options...', opts);
    }
  }

  private connectPusher = () => {
    const opts = ['cluster', 'encrypted', 'authEndpoint'].reduce((sum, key) => {
      const value = environment.pusher[key];
      if (typeof value !== 'undefined') {
        sum[key] = value;
      }
      return sum;
    }, {});
    this.pusher = new Pusher(environment.pusher.key, opts);
  }
}
