import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector   : 'dk-dialog',
  templateUrl: './dk-dialog.component.html',
  styleUrls: ['./dk-dialog.component.scss']
})
export class DkDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DkDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {}
}
