import {FuseNavigation} from '@fuse/types';
import Constants from '@shared/constants/constants';

export const navigation: FuseNavigation[] = [
  {
    id: 'apps',
    title: 'Apps',
    type: 'group',
    children: [
      {
        id: 'system',
        title: 'System',
        type: 'item',
        icon: 'developer_board',
        url: '/dashboard/system',
        roles: Constants.roles.developer
        // roles: Constants.roles.systemAdmin
      },
      {
        id: 'dashboards',
        title: 'Dashboards',
        type: 'item',
        icon: 'dashboard',
        url: '/dashboard/dashboard',
        roles: Constants.roles.dashboardAdmin
      },
      {
        id: 'group-summary',
        title: 'Group Summary',
        type: 'item',
        icon: 'group',
        url: '/dashboard/group-summary',
        roles: Constants.roles.systemAdmin
      },
      {
        id: 'chat',
        title: 'Chat',
        type: 'item',
        icon: 'chat',
        url: '/chat',
        roles: Constants.roles.user
      }
    ]
  },
  {
    id: 'reports',
    title: 'Reports',
    type: 'group',
    children: [
      {
        id: 'agent',
        title: 'Agent',
        type: 'item',
        icon: 'email',
        url: '/reports/agent',
        roles: Constants.roles.admin
      },
      {
        id: 'messages',
        title: 'Messages',
        type: 'item',
        icon: 'email',
        url: '/reports/message/all',
        roles: Constants.roles.admin
      },
      {
        id: 'samsungCare',
        title: 'Samsung Care+',
        type: 'item',
        icon: 'email',
        url: '/reports/message/samsungCare',
        roles: Constants.roles.admin
      },
      {
        id: 'Survey Result',
        title: 'Survey Result',
        type: 'item',
        icon: 'email',
        url: '/reports/survey',
        roles: Constants.roles.admin
      },
      {
        id: 'groupChat',
        title: 'Chat Summary',
        type: 'item',
        icon: 'email',
        url: '/reports/chatSummary',
        roles: Constants.roles.admin
      },
    ]
  },
  {
    id: 'broadcast',
    title: 'Broadcast',
    type: 'group',
    children: [
      {
        id: 'ACCESSORY-EXCHANGE-PGR',
        title: 'Accessory Exchange PGR',
        type: 'item',
        icon: 'list_alt',
        url: '/broadcast/groups/ACCESSORY-EXCHANGE-PGR',
        roles: Constants.roles.companyImBosAdmin
      },
      {
        id: 'ACCESSORY-EXCHANGE-BOS',
        title: 'Accessory Exchange BOS',
        type: 'item',
        icon: 'list_alt',
        url: '/broadcast/groups/ACCESSORY-EXCHANGE-BOS',
        roles: Constants.roles.companyImPgrAdmin
      },
      {
        id: 'SEA-REPAIR-SHIP',
        title: 'Repair Ship Notification',
        type: 'item',
        icon: 'list_alt',
        url: '/broadcast/groups/SEA-REPAIR-SHIP',
        roles: Constants.roles.companyImRepairAdmin
      },
    ]
  },
  {
    id: 'systems',
    title: 'System',
    type: 'group',
    children: [
      {
        id: 'batch',
        title: 'Batch',
        type: 'item',
        icon: 'access_alarm',
        url: '/reports/batch',
        roles: Constants.roles.systemAdmin
      },
    ]
  },
  {
    id: 'misc',
    title: 'Misc',
    type: 'group',
    children: [
      {
        id: 'profile',
        title: 'Profile',
        type: 'item',
        icon: 'person',
        url: '/my/profile',
        roles: Constants.roles.user
      },
    ]
  },
  {
    id: 'admin',
    title: 'Admin',
    type: 'group',
    children: [
      {
        id: 'company',
        title: 'Company',
        type: 'item',
        icon: 'account_box',
        url: '/admin/company',
        roles: Constants.roles.systemAdmin
      },
      {
        id: 'user',
        title: 'Groups',
        type: 'item',
        icon: 'group',
        url: '/admin/user',
        roles: Constants.roles.admin
      },
      {
        id: 'broadcast-admin',
        title: 'Broadcast',
        type: 'item',
        icon: 'list_alt',
        url: '/broadcast/groups/all',
        roles: Constants.roles.systemAdmin
      },
      {
        id: 'dialer',
        title: 'Dialer',
        type: 'item',
        icon: 'phone',
        url: '/phone/dialer',
        roles: Constants.roles.developer
      },
    ]
  }
];

