import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from '@auth/auth.service';

@Component({
  selector: 'app-security-code',
  templateUrl: './security-code.component.html',
  styleUrls: ['./security-code.component.scss'],
  animations: fuseAnimations
})
export class SecurityCodeComponent implements OnInit {

  securityCodeForm: FormGroup;
  securityCodeErrors: any;
  spinner = false;
  email: string = null;
  code: string = null;
  error: null;

  constructor(private fuseConfig: FuseConfigService,
              private formBuilder: FormBuilder,
              private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router) {

    this.fuseConfig.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.securityCodeErrors = {
      code: {}
    };

    this.route.queryParams.subscribe(params => {
      this.email = params.email;
      this.code = params.code;
    });

    if (!this.email) {
      this.router.navigate(['/auth/forgot-password']);
    }
  }

  ngOnInit() {
    this.securityCodeForm = this.formBuilder.group({
      code: [this.code ? this.code : '', [Validators.required]]
    });

    this.securityCodeForm.valueChanges.subscribe(() => {
      this.onSecurityCodeFormValuesChanged();
    });

    if (this.email && this.code) {
      this.securityCode();
    }
  }

  onSecurityCodeFormValuesChanged() {
    for (const field in this.securityCodeErrors) {
      if (!this.securityCodeErrors.hasOwnProperty(field)) {
        continue;
      }

      this.securityCodeErrors[field] = {};

      const control = this.securityCodeForm.get(field);

      if (control && control.dirty && !control.valid) {
        this.securityCodeErrors[field] = control.errors;
      }
    }
  }

  securityCode() {
    const value = this.securityCodeForm.value;
    this.spinner = true;

    this.authService.validateCode(this.email, value.code)
      .subscribe((response: any) => {
        setTimeout(() => {
          this.spinner = false;
        });

        console.log(response);
        this.authService.setToken(response);
        this.router.navigate(['/auth/change-password'], {
          queryParams: {
            email: this.email,
            code: value.code
          }
        });

      }, (response: any) => {
        this.error = response.error.message;
        setTimeout(() => {
          this.spinner = false;
        });
      });

    return false;
  }

}
