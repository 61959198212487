import {BehaviorSubject, Observable, throwError as observableThrowError} from 'rxjs';

import {catchError, filter, finalize, switchMap, take} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Router} from '@angular/router';

@Injectable()
export class AuthResponseInterceptor implements HttpInterceptor {

  isRefreshingToken = false;
  accessToken$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private injector: Injector,
              private router: Router,
              private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

    return next
      .handle(request).pipe(
        catchError(response => {
          if (response instanceof HttpErrorResponse) {
            if (response.status === 401) {
              return this.handleError(request, next);
            }
          }

          return observableThrowError(response);
        }));
  }

  private handleError(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {

      this.isRefreshingToken = true;
      this.accessToken$.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((res: any) => {
          if (res) {
            this.authService.setToken(res);

            const accessToken = res.accessToken;
            this.accessToken$.next(accessToken);

            return next.handle(this.setAuthHeader(request, accessToken));
          }

          return this.logoutUser();
        }),
        catchError(error => {
          console.error('Error while processing refresh token', error);
          return this.logoutUser();
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }), );
    } else {
      return this.accessToken$.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.setAuthHeader(request, token));
        }),);
    }
  }

  private setAuthHeader(req: HttpRequest<any>, accessToken: string): HttpRequest<any> {
    return req.clone({setHeaders: {Authorization: 'Bearer ' + accessToken}});
  }

  private logoutUser() {
    this.authService.logout();
    return observableThrowError('');
  }
}
